body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 700px) {
  .availability-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .footer-container {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    width: 100%;
  }

  .right-footer {
    flex-grow: 5;
    text-align: end;
  }

  .left-footer {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .footer-link {
    padding-right: 30px;
  }

  .team-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0px;
    min-width: 90%;
  }
}

@media (max-width: 699px) {
  .availability-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    width: 100%;
  }

  .footer-link {
    padding-bottom: 10px;
  }

  .left-footer {
    padding-bottom: 20px;
  }

  .team-cards {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0px;
  }
}

.listing-card {
  margin: 10px;
}

.section-heading {
  padding-bottom: 20px;
}

.card-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-pricing {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.card-price {
  font-size: 20px;
  font-weight: 500;
  margin-top: -4px;
}

.nonhome-page {
  padding-top: 60px;
}

@media (max-width: 991px) {
  .nonhome-page {
    padding-top: 90px;
  }
}

.footer-section {
  border-top: 1px solid #dddeee;
  margin-top: 100px;
  padding: 40px 0px;
}

.footer-link a {
  color: #66615B;
  font-weight: bold;
  text-decoration: none;
}

.footer-link a:hover {
  text-decoration: underline;
}

@media (min-width: 1000px) {
  .overview-boxes {
    display: flex;
    padding-top: 15px;
    flex-direction: row;
  }

  .roomhouse-container:first-of-type {
    padding-right: 40px;
  }

  .roomhouse-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 40px;
  }
}

@media (max-width: 999px) {
  .overview-boxes {
    display: flex;
    padding-top: 15px;
    flex-direction: column;
  }

  .roomhouse-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 50px;
  }

  .roomhouse-container:first-of-type {
    padding-bottom: 20px;
  }
}

.bold {
  font-weight: bold;
}

.primaryColorText, .primaryColorText:hover, .primaryColorText:link, .primaryColorText:visited, .primaryColorText:active {
  color: #099790;
}

.listing-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 10px;
}

.reserve-button {
  background-color: #099790;
  border-color: #099790;
}

.reserve-button:focus {
  background-color: #099790;
  border-color: #099790;
}

.reserve-button:active {
  background-color: #099790;
  border-color: #099790;
}

.reserve-button:hover {
  background-color: #07827c;
  border-color: #07827c;
}

.overview-detail {
  display: flex;
  flex-direction: column;
}

.roomhouse-title {
  border-bottom: 1px solid #cccccc;
}

.overview-value {
  font-weight: bold;
}

.listing-description {
  padding-top: 30px;
}

.amenity-group .material-symbols-rounded {
  color: #099790;
  padding: 10px 0;
  padding-right: 10px;
}

.amenity-group {
  display: flex;
  align-items: center;
}

.listing-amenities {
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.amenity-columns {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.amenity-column-1 {
  flex: 1;
}

.amenity-column-2 {
  flex: 1;
}

.amenity-column-3 {
  flex: 1;
}

.description-title {
  font-weight: 500;
}

.description-words {
  padding-bottom: 15px;
}

.listing-image-container {
  position: relative;
}

.listing-hero-image {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}

.hero-image-button {
  position: absolute;
  top: 2%;
  right: 2%;
}

.unit-image-container {
  padding-bottom: 25px;
}

.unit-image {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
}

.image-modal-header {
  display: flex;
  flex-direction: row;
  padding: 10px 10px 10px 50px;
  border-bottom: 1px solid #dddddd;
  justify-content: space-between;
  align-items: center;
}

.how-to-apply-container {
  padding-top: 20px;
}

.step-container {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}

.step-number {
  width: 60px;
  height: 60px;

  border-style: solid;
  border-width: 3px;
  border-color: #099790;
  color: #099790;

  font-weight: 700;
  border-radius: 300px;
  text-align: center;
  vertical-align: middle;
  font-size: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.step {
  padding-left: 20px;
}

.questions-description {
  padding-top: 10px;
}

.interest-form-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.form-instructions {
  padding-top: 20px;
}

.form-complete {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.check-image {
  width: 30%;
  max-width: 150px;
  padding-bottom: 40px;
}

.form-complete-description {
  /* border: 1px red solid; */
  /* font-weight: bold; */
  font-size: large;
  width: 100%;
  text-align: center;
}

.location-description {
  padding-top: 10px;
  padding-bottom: 16px;
}

.view-map-button {
  margin-top: 10px;
}

.map-container {
  border-radius: 12px;
  width: 100%; 
  height: 350px;
  overflow: hidden;
  margin-top: 12px;
}

.map-marker-container {
  position: 'absolute';
  height: 40px;
  width: 40px;

  background-color: white;
  border-radius: 20px;
  border: 3px #099790 solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    border-radius: 45px;
    background-color: #099790;
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}

.about-amsoo {
  padding: 80px 0px;
  text-align: center;
}

.about-page-header {
  width: 100%;
  min-height: 40vh;
  background-size: cover;
  background-position: center center;
}

.dark-overlay {
  width: 100%;
  height: 40vh;
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-text {
  padding: 20px;
  color: #66615B;
  font-weight: 200;
}

.meet-team-title {
  padding: 20px 0px;
  padding-bottom: 0px;
  font-weight: 800;
  color: #099790;
  text-align: center;
  font-size: 3em;
}

.meet-the-team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  padding-top: 40px;
}

.team-name {
  flex: 1;
  padding: 3px;
  padding-top: 10px;
  font-size: x-large;
  font-weight: 700;
}

.team-title {
  flex: 1;
  padding: 0px;
  font-size: larger;
  font-weight: 500;
}

.why-rent {
  padding-top: 40px;
}

.why-rent-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.why-rent-row {
  display: flex;
  flex-direction: row;
}

.why-rent-row :nth-child(2) {
  background-color: #00fff2;
}

.why-rent-col {
  display: flex;
  flex: 1;
  padding-bottom: 10px;
}

.why-rent-col-option {
  display: flex;
  justify-content: center;
  flex: 1;
  text-align: center;
  padding-bottom: 10px;
}

.why-rent-col-option .red {
  color: rgb(226, 0, 0);

}

.why-rent-col-option .green {
  color: rgb(37, 147, 37);
}

.spinner-container {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

.spinner {
  color: #099790;
}